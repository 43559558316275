.custom-back{
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: 1;
    /* padding: 0.5rem; */
}

.custom-back::before{
    content: "";
    background: url('../../assets//sample.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 60% 120%;
    opacity: 0.7;
}

@media only screen and (max-width:1300px){
    .custom-back{
        background-position: 35vw 0%;
    }
}
@media only screen and (max-width:800px){
    .custom-back::before{
        /* height: 40vh; */
        background-size: cover;
        background-position: center;
        opacity: 0.25;
    }
}