.video{
    /* width: 100vw; */
    height: 100vh;
    margin: auto;
}

@media only screen and (max-width:800px){
    .video{
        height: auto;
    }
}