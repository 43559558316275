@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Oswald', sans-serif;
    /* font-family: 'Roboto Mono', monospace; */
    scroll-behavior: smooth;
    scroll-padding-top: 50px;
    -moz-user-select: none;
    /* firefox */
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE*/
    user-select: none;
    /* Standard syntax */
}

/* #35009D -- Blue

    #FA66C5 -- Pink
    
    #0d4cb1 -- light blue*/