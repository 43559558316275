
 #get-demo {
    position: relative;
 }

 .width-map{
   width: 40vw;
   height: 50vh;
 }
 
 /* .get-a-demo-border {
    padding: 3px;
    background: linear-gradient(to right, #00888F, #580053);
    border-radius: 10px;
 } */
 
 .lower-conter-right {
    width: 40vw;
    max-width: 450px;
    /* background-image: url("../../assets/img/home/bg-lower-contact.png"); */
 }
 
 .get-a-demo-form {
    /* background-color: white; */
    background: linear-gradient(to bottom right,#FA66C5,#0d4cb1);
    color: white;
    border-radius: 7px;
    padding: 20px;
    padding-right: 23px;
 
 }
 
 #get-1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: white;
    margin-bottom: 40px;
 }
 
 .get-a-demo-form p {
    font-weight: bold;
    color: white;
 }
 
 .get-a-demo-form input,
 textarea {
    width: 100%;
    height: 40px;
    background-color: rgb(246, 246, 246);
    border: none;
    color: black;
    border-radius: 15px;
 }
 
 #input-2 {
    margin-bottom: 30px;
    height: 80px;
 }
 
 .get-a-demo-form button {
    background: #35009D;
    border: none;
    color: white;
    padding: 10px 22px;
    font-size: 22px;
    border-radius: 5px;
    display: block;
    margin: auto;
    transition-duration: 0.5s;
 }
 
 .get-a-demo-form button:hover {
    transform: scale(1.05);
    cursor: pointer;
 }
 
 .lower-text-contact {
    font-size: 11px;
    font-weight: 400 !important;
    color: rgb(73, 73, 73);
    margin-top: 30px;
    text-align: center;
 }
 
 .lower-text-contact a {
    text-decoration: none;
    color: #580053;
 }
 
 @media only screen and (max-width:1100px) {
    .heading-lower-contact {
       font-size: 25px;
       width: 95%;
    }
    .width-map{
      width: 90vw;
      height: 50vh;
    }
 
    .lower-conten-box {
       flex-wrap: wrap;
    }
 
    .lower-content-left {
       width: 93vw;
       max-width: 90%;
       font-weight: bold;
       font-size: 15px;
   }
   #lowfootimg{
    width: 97%;
    margin-bottom: 2rem;
   }
   .lower-conter-right{
    width: 90%;
    max-width: 90%;
   }
 }